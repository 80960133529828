export const toursVoucher = `
<style>
	.ps-3 {
		-webkit-padding-start: 1rem !important;
		padding-inline-start: 1rem !important;
	}
	.ms-2 {
		-webkit-margin-start: 0.5rem !important;
		margin-inline-start: 0.5rem !important;
	}
	.red-bg {
		background-color: #b52555;
	}
	.telephone-bg {
		background-color: #c4d600;
	}
	.telephone-border {
		border: 1px solid #c4d600;
	}
	.col,
	.col-1,
	.col-10,
	.col-11,
	.col-12,
	.col-2,
	.col-3,
	.col-4,
	.col-5,
	.col-6,
	.col-7,
	.col-8,
	.col-9,
	.col-auto,
	.col-lg,
	.col-lg-1,
	.col-lg-10,
	.col-lg-11,
	.col-lg-12,
	.col-lg-2,
	.col-lg-3,
	.col-lg-4,
	.col-lg-5,
	.col-lg-6,
	.col-lg-7,
	.col-lg-8,
	.col-lg-9,
	.col-lg-auto,
	.col-md,
	.col-md-1,
	.col-md-10,
	.col-md-11,
	.col-md-12,
	.col-md-2,
	.col-md-3,
	.col-md-4,
	.col-md-5,
	.col-md-6,
	.col-md-7,
	.col-md-8,
	.col-md-9,
	.col-md-auto,
	.col-sm,
	.col-sm-1,
	.col-sm-10,
	.col-sm-11,
	.col-sm-12,
	.col-sm-2,
	.col-sm-3,
	.col-sm-4,
	.col-sm-5,
	.col-sm-6,
	.col-sm-7,
	.col-sm-8,
	.col-sm-9,
	.col-sm-auto,
	.col-xl,
	.col-xl-1,
	.col-xl-10,
	.col-xl-11,
	.col-xl-12,
	.col-xl-2,
	.col-xl-3,
	.col-xl-4,
	.col-xl-5,
	.col-xl-6,
	.col-xl-7,
	.col-xl-8,
	.col-xl-9,
	.col-xl-auto {
		padding-right: 5px;
		padding-left: 5px;
	}
	.text-right {
		text-align: right !important;
	}
	.text-white {
		color: #fff !important;
	}
	.font-weight-bold {
		font-weight: 700 !important;
	}
	.text-center {
		text-align: center !important;
	}
	.text-right {
		text-align: right !important;
	}
	.pb-4,
	.py-4 {
		padding-bottom: 1.5rem !important;
	}
	.pt-4,
	.py-4 {
		padding-top: 1.5rem !important;
	}
	.p-3 {
		padding: 1rem !important;
	}
	.pt-2,
	.py-2 {
		padding-top: 0.5rem !important;
	}
	.p-2 {
		padding: 0.5rem !important;
	}
	.ml-5,
	.mx-5 {
		margin-left: 3rem !important;
	}
	.mr-5,
	.mx-5 {
		margin-right: 3rem !important;
	}
	.mb-4,
	.my-4 {
		margin-bottom: 1.5rem !important;
	}
	.mb-2,
	.my-2 {
		margin-bottom: 0.5rem !important;
	}
	.mt-2,
	.my-2 {
		margin-top: 0.5rem !important;
	}
	.m-0 {
		margin: 0 !important;
	}
	.h-100 {
		height: 100% !important;
	}
	.w-100 {
		width: 100% !important;
	}
	.align-items-end {
		-webkit-align-items: flex-end !important;
		align-items: flex-end !important;
	}
	.justify-content-center {
		-webkit-justify-content: center !important;
		justify-content: center !important;
	}
	.flex {
		-webkit-flex: 1 !important;
		flex: 1 !important;
		padding-inline-end: 1rem;
	}
	.flex-column {
		-webkit-flex-direction: column !important;
		flex-direction: column !important;
	}
	.d-flex {
		display: -webkit-flex !important;
		display: flex !important;
	}
	.border-bottom {
		border-bottom: 1px solid #dee2e6 !important;
	}
	.border {
		border: 1px solid #dee2e6 !important;
	}
	.bg-light {
		background-color: #f8f9fa !important;
	}
	.col-9 {
		-webkit-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-6 {
		-webkit-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-3 {
		-webkit-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-xl,
	.col-xl-auto,
	.col-xl-12,
	.col-xl-11,
	.col-xl-10,
	.col-xl-9,
	.col-xl-8,
	.col-xl-7,
	.col-xl-6,
	.col-xl-5,
	.col-xl-4,
	.col-xl-3,
	.col-xl-2,
	.col-xl-1,
	.col-lg,
	.col-lg-auto,
	.col-lg-12,
	.col-lg-11,
	.col-lg-10,
	.col-lg-9,
	.col-lg-8,
	.col-lg-7,
	.col-lg-6,
	.col-lg-5,
	.col-lg-4,
	.col-lg-3,
	.col-lg-2,
	.col-lg-1,
	.col-md,
	.col-md-auto,
	.col-md-12,
	.col-md-11,
	.col-md-10,
	.col-md-9,
	.col-md-8,
	.col-md-7,
	.col-md-6,
	.col-md-5,
	.col-md-4,
	.col-md-3,
	.col-md-2,
	.col-md-1,
	.col-sm,
	.col-sm-auto,
	.col-sm-12,
	.col-sm-11,
	.col-sm-10,
	.col-sm-9,
	.col-sm-8,
	.col-sm-7,
	.col-sm-6,
	.col-sm-5,
	.col-sm-4,
	.col-sm-3,
	.col-sm-2,
	.col-sm-1,
	.col,
	.col-auto,
	.col-12,
	.col-11,
	.col-10,
	.col-9,
	.col-8,
	.col-7,
	.col-6,
	.col-5,
	.col-4,
	.col-3,
	.col-2,
	.col-1 {
		position: relative;
		width: 100%;
		padding-right: 15px;
		padding-left: 15px;
	}
	.row {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		margin-right: -15px;
		margin-left: -15px;
	}
	.img-fluid {
		max-width: 100%;
		height: auto;
	}
	h6,
	.h6 {
		font-size: 1rem;
	}
	h5,
	.h5 {
		font-size: 1.25rem;
	}
	h4,
	.h4 {
		font-size: 1.5rem;
	}
	h3,
	.h3 {
		font-size: 1.75rem;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.h1,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6 {
		margin-bottom: 0.5rem;
		font-weight: 500;
		line-height: 1.2;
	}
	html,
	body,
	div,
	dl,
	dt,
	dd,
	ul,
	ol,
	li,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	pre,
	code,
	form,
	fieldset,
	legend,
	input,
	textarea,
	p,
	blockquote,
	th,
	td {
		margin: 0;
		padding: 0;
	}
	* {
		font-family: "Cairo", sans-serif;
	}
	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}
  .page {
    height: 27.7cm;
    width: 21cm;
    margin: 2cm auto;
  }
</style>

  <div class="mx-5 page">
    <div class="d-flex align-items-end flex-column w-100 text-center">
      <div>
        <p class="mb-2 font-weight-bold">Guaranteed by</p>
        [logo]
      </div>
    </div>
    <div class="text-center border bg-light p-2 mt-2">
      <h3 class="h3 font-weight-bold">Voucher - Tickets and Excursions</h3>
      <p>Booking confirmed and guaranteed - Voucher - Ticket</p>
    </div>

    <div class="border mt-2">
      <div class="d-flex m-0 border-bottom" style="gap: 2px">
        <div class="col-3 text-center py-4 d-flex justify-content-center flex-column text-white red-bg">
          <p class="font-weight-bold">[reference_number_key]:</p>
          <h5 class="h5 font-weight-bold">[reference_number_value]</h5>
        </div>
        <div class="col-3 text-center py-4 d-flex justify-content-center flex-column text-white"
          style="background-color: #d692ab">
          <p class="font-weight-bold">[safa_reference_number_key]:</p>
          <h5 class="h5 font-weight-bold">[safa_reference_number_value]</h5>
        </div>
        <div class="col-6 row m-0 p-2">
          <h5 class="h5 font-weight-bold w-100">[name]</h5>
          <div class="row">
            <div class="col-6">
              <h6 class="h6 font-weight-bold">[passenger_name_key]:</h6>
            </div>
            <div class="col-6 text-right">
              <p>[passenger_name_value]</p>
            </div>
            <div class="col-6">
              <h6 class="h6 font-weight-bold">[booking_date_key]:</h6>
            </div>
            <div class="col-6 text-right">
              <p>[booking_date_value]</p>
            </div>
            <div class="col-6">
              <h6 class="h6 font-weight-bold">[agency_Reference_key]:</h6>
            </div>
            <div class="col-6 text-right">
              <p>[agency_Reference_value]</p>
            </div>
          </div>
        </div>
      </div>

      <div class="p-2 border-bottom">
        <span class="h5 font-weight-bold">[from_key]</span>
        <span class="p-2">[from_value]</span>
        <span class="p-2">-</span>
        <span class="h5 font-weight-bold">[to_key]</span>
        <span class="p-2">[to_value]</span>
      </div>

      <div class="p-2 border-bottom d-flex">
        <div class="flex">
          <h6 class="h6 font-weight-bold mb-4">[ticket_type_key]</h6>
          <p>[ticket_type_vaue]</p>
        </div>
        <div class="flex">
          <h6 class="h6 font-weight-bold mb-4">[destination_key]</h6>
          <p>[destination_key_value]</p>
        </div>
        <!-- <div class="flex">
          <h6 class="h6 font-weight-bold mb-4">[service_time_key]</h6>
          <p>[service_time_value]</p>
        </div> -->
        <div class="flex">
          <h6 class="h6 font-weight-bold mb-4">[adult_key]</h6>
          <p>[adult_value]</p>
        </div>
        <div class="flex">
          <h6 class="h6 font-weight-bold mb-4">[children_key]</h6>
          <p>[children_value]</p>
        </div>
        <div class="flex">
          <h6 class="h6 font-weight-bold mb-4">[children_ages_key]</h6>
          <p>[children_ages_value]</p>
        </div>
      </div>

      <div class="p-2">
        <h6 class="h6 font-weight-bold">[remarks_key] :</h6>
        <p>[remarks_value]</p>
      </div>
    </div>

    <div class="border mt-2">
      <div class="p-2">
        <h4 class="h6 font-weight-bold">Provider</h4>
        <p class="my-2">[provider_name_value]</p>
        <p class="my-2">Ref. Supplier: [provider_ref_value]</p>
      </div>
    </div>

    <div class="mt-2 telephone-border">
      <div class="w-100 p-2 telephone-bg">
        <h6 class="h6 font-weight-bold text-white">Telephone:</h6>
      </div>
      <div class="p-3 d-flex">
        [phone_img]
        <div class="ms-2">
          <h6 class="h6 font-weight-bold">Emergency number</h6>
          <h6 class="h6 font-weight-bold">+34871180153 - +6620268386</h6>
        </div>
      </div>
    </div>
    <p class="text-center my-2">
      Booked and payable by
      <span>[supplier_name_value]</span>
      <span>[supplier_vat_value]</span>
    </p>
  </div>
`;
