import visaImg from "assets/images/visaImg.svg";
import { useSBSDispatch } from "context/global";

import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { logOutVisa, viewUser } from "services/visa";

import Locale from "translations";

export default function SafaProfile() {
	const { messages } = Locale;
	const tokenSafaVisa = localStorage.getItem("VisaToken");
	let history = useHistory();
	const dispatch = useSBSDispatch();

	const [loginData, setLoginData] = useState({
		safa_key: "",
		email: "",
	});

	useEffect(() => {
		const fetchUser = async () => {
			const res = await viewUser({ token: tokenSafaVisa });
			setLoginData({ ...res.data.data });
		};

		fetchUser();
	}, []);

	// const logOutUser = async () => {
	// 	let requestBody = {
	// 		token: localStorage.getItem("VisaToken"),
	// 	};
	// 	const response = await logOutVisa(requestBody);
	// 	if (response?.status === 200) {
	// 		localStorage.removeItem("VisaToken");
	// 		history.push("/");
	// 	} else {
	// 		dispatch({
	// 			type: "setError",
	// 			payload: true,
	// 			message: {
	// 				title: null,
	// 				body: response?.data?.message,
	// 			},
	// 		});
	// 	}
	// 	// setSpin(false);
	// };

	return (
		<>
			<div className="container ">
				<div className=" d-flex align-items-center   log-first  ">
					<div className="d-flex container  justify-content-start align-items-center ">
						{/* Left Banner */}
						<figure className="col-md-5 pt-3 m-0 d-flex  justify-content-center px-5 company-profile-safa-tab-img">
							<img src={visaImg} alt="" className="img-fluid" />
						</figure>

						{/* Login Form */}
						<div className="col-md-5 d-flex  flex-column justify-content-between company-profile-visa-success">
							<div className="w-100 ">
								<h1 className="text-title font-weight-bold ">
									{messages.AccountLinked}
								</h1>
								<p className="mb-4">{messages.manageAccount}</p>
								<div className="col-md-8">
									<div className="d-flex">
										<p className="m-0 ">{messages.safakey} </p>
										<p className="m-0 font-weight-bold">{loginData.safa_key}</p>
									</div>
									<div className="d-flex my-2">
										<p className="m-0 ">{messages.email} </p>
										<p className="m-0 font-weight-bold">{loginData.email}</p>
									</div>
								</div>

								{/* <button
									className="btn bg-nxt w-75 mt-4"
									onClick={logOutUser}
									// disabled={spin}
								>
									// {spin ? <Spinner color="light" size="sm" /> : null}
									{"  "} {messages.logout}
								</button> */}
							</div>
							<div></div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
