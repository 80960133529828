import { useHistory } from "react-router-dom";
import Locale from "translations";

export default function AboutUs() {
	const { newLand, companyDetails } = Locale;
	const history = useHistory();
	return (
		<div className="bg-white">
			<div class="w-100 py-5 bg-light  d-flex justify-content-between align-items-center">
				<div></div>
				<h2 className="h2">{newLand.aboutUs}</h2>
				<div className='mx-2'>
					<button className="btn btn-warning" onClick={()=>history.goBack()}>
						
						{companyDetails.back}
						<i className='fa fa-arrow-left mx-1'></i>
						</button>
				</div>
			</div>
			<div>
				<div className="row my-3 about-container">
					<p className="my-5 col-12">{newLand.aboutSafa}</p>
					<div className="col-lg-6 col-12 about-details">
						<p>{newLand.aboutFirstParagraph}</p>
						<p>{newLand.aboutSecondParagraph}</p>
						<p>{newLand.aboutThirdParagraph}</p>
						<div title="Page 49">
							<div>
								<div>
									<div>
										<p>{newLand.aboutFourthParagraph}</p>
										<p>{newLand.aboutFifthParagraph}</p>
										<p>{newLand.aboutSexthParagraph}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-6 col-12">
						<div class="elementor-image ">
							<img
								width="896"
								height="896"
								src="https://safasoft.com/wp-content/uploads/2020/12/10-years-1.jpg"
								class="attachment-large size-large img-fluid"
								alt=""
								loading="lazy"
								srcset="https://safasoft.com/wp-content/uploads/2020/12/10-years-1.jpg 896w, https://safasoft.com/wp-content/uploads/2020/12/10-years-1-300x300.jpg 300w, https://safasoft.com/wp-content/uploads/2020/12/10-years-1-150x150.jpg 150w, https://safasoft.com/wp-content/uploads/2020/12/10-years-1-768x768.jpg 768w, https://safasoft.com/wp-content/uploads/2020/12/10-years-1-480x480.jpg 480w, https://safasoft.com/wp-content/uploads/2020/12/10-years-1-800x800.jpg 800w, https://safasoft.com/wp-content/uploads/2020/12/10-years-1-400x400.jpg 400w"
								sizes="(max-width: 896px) 100vw, 896px"
								title="10-years"
							/>
						</div>
					</div>
				</div>
				<div className="process_main_container text-center cloud_animated_bottom ">
					<p className="py-4">{newLand.ourStory}</p>
					<h3 className="h3 mb-4">{newLand.highlights}</h3>
					<div class="process_bar_container row process_bar_container--type-vertical">
						<div class="process_item span12 ">
							<div class="process_item__number">2009</div>

							<div class="process_item__circle_wrapp">
								<div class="process_item__circle_line_before"></div>
								<div class="process_item__circle process_item__circle--size_e_large"></div>
								<div class="process_item__circle_line_after"></div>
							</div>

							<div class="process_item__content">
								<div class="process_item__content_wrapper">
									<div class="process_item__heading"></div>
									<div class="process_item__description">
										{newLand.about2009}
									</div>
								</div>
							</div>
						</div>

						<div class="process_item span12 ">
							<div class="process_item__number">2012</div>
							<div class="process_item__circle_wrapp">
								<div class="process_item__circle_line_before"></div>
								<div class="process_item__circle process_item__circle--size_mini"></div>
								<div class="process_item__circle_line_after"></div>
							</div>
							<div class="process_item__content">
								<div class="process_item__content_wrapper">
									<div class="process_item__heading"></div>
									<div class="process_item__description">
										{newLand.about2012}
									</div>
								</div>
							</div>
						</div>

						<div class="process_item span12 ">
							<div class="process_item__number">2014</div>
							<div class="process_item__circle_wrapp">
								<div class="process_item__circle_line_before"></div>
								<div class="process_item__circle process_item__circle--size_mini"></div>
								<div class="process_item__circle_line_after"></div>
							</div>
							<div class="process_item__content">
								<div class="process_item__content_wrapper">
									<div class="process_item__heading"></div>
									<div class="process_item__description">
										{newLand.about2014}
									</div>
								</div>
							</div>
						</div>

						<div class="process_item span12 ">
							<div class="process_item__number">2015</div>
							<div class="process_item__circle_wrapp">
								<div class="process_item__circle_line_before"></div>
								<div class="process_item__circle process_item__circle--size_mini"></div>
								<div class="process_item__circle_line_after"></div>
							</div>
							<div class="process_item__content">
								<div class="process_item__content_wrapper">
									<div class="process_item__heading"></div>
									<div class="process_item__description">
										{newLand.about2015}
									</div>
								</div>
							</div>
						</div>

						<div class="process_item span12 ">
							<div class="process_item__number">2017</div>
							<div class="process_item__circle_wrapp">
								<div class="process_item__circle_line_before"></div>
								<div class="process_item__circle process_item__circle--size_mini"></div>
								<div class="process_item__circle_line_after"></div>
							</div>
							<div class="process_item__content">
								<div class="process_item__content_wrapper">
									<div class="process_item__heading"></div>
									<div class="process_item__description">
										{newLand.about2017}
									</div>
								</div>
							</div>
						</div>

						<div class="process_item span12 ">
							<div class="process_item__number">2018</div>
							<div class="process_item__circle_wrapp">
								<div class="process_item__circle_line_before"></div>
								<div class="process_item__circle process_item__circle--size_e_large"></div>
								<div class="process_item__circle_line_after"></div>
							</div>
							<div class="process_item__content">
								<div class="process_item__content_wrapper">
									<div class="process_item__heading"></div>
									<div class="process_item__description">
										{newLand.about2018}
									</div>
								</div>
							</div>
						</div>

						<div class="process_item span12 ">
							<div class="process_item__number">2019</div>
							<div class="process_item__circle_wrapp">
								<div class="process_item__circle_line_before"></div>
								<div class="process_item__circle process_item__circle--size_mini"></div>
								<div class="process_item__circle_line_after"></div>
							</div>
							<div class="process_item__content">
								<div class="process_item__content_wrapper">
									<div class="process_item__heading"></div>
									<div class="process_item__description">
										{newLand.about2019}
									</div>
								</div>
							</div>
						</div>

						<div class="process_item span12 ">
							<div class="process_item__number">2020</div>
							<div class="process_item__circle_wrapp">
								<div class="process_item__circle_line_before"></div>
								<div class="process_item__circle process_item__circle--size_mini"></div>
								<div class="process_item__circle_line_after"></div>
							</div>
							<div class="process_item__content">
								<div class="process_item__content_wrapper">
									<div class="process_item__heading"></div>
									<div class="process_item__description">
										{newLand.about2020}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
