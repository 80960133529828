import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import SelectField from "components/Form/SelectField/SelectField";
import HotelPlaceHolder from "assets/images/new-hotels.svg";

import TextField from "components/Form/TextField/TextField";
import { useSBSState } from "context/global";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { fetchAirPorts, fetchFlights } from "services/marketplace";
import Locale from "translations";
import { isInclusivelyBeforeDay } from "react-dates";
import moment from "moment";
import validate, { isFormValid } from "helpers/validate";
import { store } from "react-notifications-component";
import {
	useMarketplaceDispatch,
	useMarketplaceState,
} from "context/marketplace";

export default function RoundTripe({
	setOptionsChildren,
	Options,
	setListAirlines,
	listAirlines,
	getListAirlines,
	modify,
	closeModal,
}) {
	const { marketPlace, inventory, messages } = Locale;
	const history = useHistory();
	const { locale } = useSBSState();
	const [flights, setFlights] = useState({children:0});
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const dispatch = useMarketplaceDispatch();
	const { flightsSearch } = useMarketplaceState();

	let goingToPrevValue = useRef(null);

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate({ name: "from", value: flights.from }, { required: true }),
			...validate({ name: "to", value: flights.to }, { required: true }),
			...validate(
				{ name: "departureDate", value: flights.departureDate },
				{ required: true }
			),
			...validate(
				{ name: "arrivalDate", value: flights.arrivalDate },
				{ required: true }
			),
			...validate(
				{ name: "adults", value: flights.adults },
				{ required: true }
			),
			...validate(
				{ name: "cabinClass", value: flights.cabinClass },
				{ required: true }
			),
		});
	};

	const handleSearch = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	useEffect(() => {
		if (modify && flightsSearch) {
			flightsSearch.departureDate = moment(flightsSearch.departureDate);
			flightsSearch.arrivalDate = moment(flightsSearch.arrivalDate);
			setOptionsChildren(+flightsSearch?.adults)
			setFlights(flightsSearch);
		}

		return () => {};
	}, []);

	useEffect(() => {
		async function handleBranches() {
			if (isFormValid(errors)) {
				let data =
					// {
					// 	adults: 1,
					// 	children: 1,
					// 	airline: "",
					// 	airLegs: [
					// 		{
					// 			cabinClass: "Economy",
					// 			departureDate: "2023-04-16",
					// 			destination: "MEL",
					// 			origin: "HKG",
					// 		},
					// 		{
					// 			cabinClass: "Economy",
					// 			departureDate: "2023-04-25",
					// 			destination: "HKG",
					// 			origin: "MEL",
					// 		},
					// 	],
					// };

					{
						adults: flights.adults,
						children: flights.children,
						airline: flights.airline?.id ?? "",
						airLegs: [
							{
								cabinClass: flights.cabinClass?.value,
								departureDate: moment(flights.departureDate).format(
									"YYYY-MM-DD"
								),
								origin: flights?.from?.code,
								destination: flights?.to?.code,
							},
							{
								cabinClass: flights.cabinClass?.value,
								departureDate: moment(flights.arrivalDate).format("YYYY-MM-DD"),
								origin: flights?.to?.code,
								destination: flights?.from?.code,
							},
						],
					};

				const response = await fetchFlights(data);
				if (response.status === 200) {
					const data = response.data;

					if (response.data.data.length > 0) {
						dispatch({
							type: "flightsSearchResults",
							payload: data,
						});
						dispatch({
							type: "saveFlightsSearch",
							payload: { ...flights, cacheKey: data?.cacheKey, type: "1" },
						});
						if (modify) {
							closeModal();
							history.location.pathname === "/inbound-view" &&
							history.push("/outbound-view");
						} else {
							history.push("/outbound-view");
						}
						dispatch({
							type: "outboundFlight",
							payload: null,
						});
						dispatch({
							type: "inboundFlight",
							payload: null,
						});
					} else {
						store.addNotification({
							title: messages.noResults,
							message: messages.noSearchResults,
							type: "danger",
							insert: "top",
							container: "top-right",
							animationIn: ["animated", "fadeIn"],
							animationOut: ["animated", "fadeOut"],
							dismiss: { duration: 3000, onScreen: true, pauseOnHover: true },
						});
					}
				}
			}
		}
		handleBranches();
	}, [isErrorLoaded]);

	function clearSearchText(e, key) {
		goingToPrevValue.current = flights[key];
		const value = e.target.value;
		if (value.length > 0) {
			setFlights({ ...flights, [key]: null });
		}
	}

	// AutoCompleteField
	const [listAuto, setListAuto] = useState([]);
	const getListAuto = async (inputValue) => {
		if (inputValue.length > 2) {
			const Airlines = await fetchAirPorts({ search: inputValue });
			let result =[]
		
			Object.keys(Airlines).sort().map(res=>{
			
				Airlines[res].map((item) => {
			
					result.push(	 {
							...item,
							name: res!=="" ?  item.names[locale]:item.names[locale],
							value: item.id,
							label: res!=="" ?  item.names[locale]:item.names[locale],
						})
				});
			})
			setListAuto(result);
		}
	};

	// AutoCompleteField
	const [listAutoTo, setListAutoTO] = useState([]);
	const getListAutoTO = async (inputValue) => {
		if (inputValue.length > 2) {
			const Airlines = await fetchAirPorts({ search: inputValue });
			let result =[]
			Object.keys(Airlines).sort().map(res=>{
				Airlines[res].map((item) => {
					result.push(	 {
							...item,
							name: res!=="" ? item.names[locale]:item.names[locale],
							value: item.id,
							label: res!=="" ?  item.names[locale]:item.names[locale],
						})
				});
			})
			setListAutoTO(result);
		}
	};

	return (
		<div className="flight-search">
			<div className="col-md-10 d-flex m-auto ">
				<div className="col-md-6 p-0 fromflight">
					<AutoCompleteField
						hasLabel={true}
						label={marketPlace.From}
						isSearchable={true}
						placeholder={marketPlace.origin}
						listAuto={listAuto}
						setListAuto={setListAuto}
						getListAuto={getListAuto}
						value={flights?.from?.name || ""}
						// flag={flights.from?.country?.flag}
						onFocus={(e) => clearSearchText(e, "from")}
						onBlur={() =>
							setFlights({
								...flights,
								from: goingToPrevValue.current,
							})
						}
						onChange={(e) => {
							setFlights({
								...flights,
								from: { name: e },
							});
						}}
						onSelectValue={(e) => {
							setFlights({
								...flights,
								from: e,
							});
							setErrors({
								...errors,
								...validate({ name: "from", value: e }, { required: true }),
							});
						}}
						color={errors?.from?.required ? "danger" : ""}
						errors={errors?.from}
					/>
					<i class="fas fa-exchange-alt"></i>
				</div>
				<div className="col-md-6 p-0  toflight">
					<AutoCompleteField
						hasLabel={true}
						label={marketPlace.To}
						isSearchable={true}
						placeholder={marketPlace.messages.destination}
						listAuto={listAutoTo}
						setListAuto={setListAutoTO}
						getListAuto={getListAutoTO}
						value={flights?.to?.name || ""}
						// flag={flights.to?.country?.flag}
						onFocus={(e) => clearSearchText(e, "to")}
						onBlur={() =>
							setFlights({
								...flights,
								to: goingToPrevValue.current,
							})
						}
						onChange={(e) => {
							setFlights({
								...flights,
								to: { name: e },
							});
						}}
						onSelectValue={(e) => {
							setFlights({
								...flights,
								to: e,
							});
							setErrors({
								...errors,
								...validate({ name: "to", value: e }, { required: true }),
							});
						}}
						color={errors?.to?.required ? "danger" : ""}
						errors={errors?.to}
					/>
				</div>
			</div>
			<div className="col-md-10 d-flex m-auto ">
				<div className="col-md-6">
					<DatePickerField
						label={marketPlace.Depart}
						placeholder={"DD/MM/YYYY"}
						date={flights.departureDate}
						onChangeDate={(date) => {
							setFlights({
								...flights,
								departureDate: date,
								arrivalDate: date,
								minArrivalDate: date,
							});
						}}
						isOutsideRange={(day) => {
							return isInclusivelyBeforeDay(day, moment());
						}}
						onBlur={() =>
							setErrors({
								...errors,
								...validate(
									{ name: "departureDate", value: flights.departureDate },
									{ required: true }
								),
							})
						}
						color={errors?.departureDate?.required ? "danger" : ""}
						errors={errors?.departureDate}
					/>
				</div>
				<div className="col-md-6">
					<DatePickerField
						label={marketPlace.Retrun}
						placeholder={"DD/MM/YYYY"}
						minDate={
							flights?.minArrivalDate
								? moment(flights?.minArrivalDate).add(-1, "d")
								: moment()
						}
						date={flights.arrivalDate}
						onChangeDate={(date) => {
							setFlights({
								...flights,
								arrivalDate: date,
							});
						}}
						onBlur={() =>
							setErrors({
								...errors,
								...validate(
									{ name: "arrivalDate", value: flights.arrivalDate },
									{ required: true }
								),
							})
						}
						color={errors?.arrivalDate?.required ? "danger" : ""}
						errors={errors?.arrivalDate}
					/>
				</div>
			</div>
			<div className="col-md-10 d-flex m-auto">
				<div className="col-md-4">
					<SelectField
						label={inventory.messages.adults}
						placeholder={marketPlace.messages.select}
						options={Options.adults}
						value={flights.adults}
						onChange={(e) => {
							setFlights({
								...flights,
								adults: e.value,
								children: 0,
							});
							setOptionsChildren(e.value);
							setErrors({
								...errors,
								...validate(
									{ name: "adults", value: e.value },
									{ required: true }
								),
							});
						}}
						onBlur={() =>
							setErrors({
								...errors,
								...validate(
									{
										name: "adults",
										value: flights.adults,
									},
									{ required: true }
								),
							})
						}
						color={errors.adults?.required ? "danger" : ""}
						errors={errors.adults}
					/>
				</div>
				<div className="col-md-4">
					<SelectField
						label={inventory.messages.children}
						placeholder={marketPlace.messages.select}
						options={Options.Children}
						value={
							flights?.children == 0
								? flights?.children.toString()
								: flights?.children
						}
						onChange={(e) => {
							setFlights({
								...flights,
								children: e.value,
							});
						}}
						color={errors.children?.required ? "danger" : ""}
						errors={errors.children}
					/>
				</div>
				<div className="col-md-4">
					<SelectField
						label={marketPlace.class}
						placeholder={marketPlace.messages.select}
						options={Options.classes}
						value={flights?.cabinClass?.name}
						onChange={(e) => {
							setFlights({
								...flights,
								cabinClass: e,
							});
							setErrors({
								...errors,
								...validate(
									{ name: "cabinClass", value: e },
									{ required: true }
								),
							});
						}}
						onBlur={() =>
							setErrors({
								...errors,
								...validate(
									{
										name: "cabinClass",
										value: flights.cabinClass,
									},
									{ required: true }
								),
							})
						}
						color={errors.cabinClass?.required ? "danger" : ""}
						errors={errors.cabinClass}
					/>
				</div>
			</div>
			<div className="col-md-10 d-flex m-auto">
				<div className="col-md-6">
					<AutoCompleteField
						hasLabel={true}
						label={marketPlace.airlines}
						placeholder={marketPlace.airlines}
						listAuto={listAirlines}
						setListAuto={setListAirlines}
						getListAuto={getListAirlines}
						value={flights?.airline?.name || ""}
						// flag={flights.airline?.country?.flag}
						onFocus={(e) => clearSearchText(e, "airline")}
						onBlur={() =>
							setFlights({
								...flights,
								airline: goingToPrevValue.current,
							})
						}
						onChange={(e) => {
							setFlights({
								...flights,
								airline: { name: e },
							});
						}}
						onSelectValue={(e) => {
							setFlights({
								...flights,
								airline: e,
							});
							setErrors({
								...errors,
								...validate({ name: "airline", value: e }, { required: false }),
							});
						}}
						color={errors?.airline?.required ? "danger" : ""}
						errors={errors?.airline}
					/>
				</div>
			</div>
			<div className="col-md-10 m-auto">
				<div className="full-input search-button">
					<div className="full-input search-button">
						<button
							onClick={() => {
								// history.push("/outbound-view");
								handleSearch();
							}}
							className="btn w-100 bg-nxt mb-3 mt-3"
							type="button"
						>
							{marketPlace.messages.search}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
