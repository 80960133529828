import markerIcon from "assets/images/map-marker-blue.svg";
import markerIconSelected from "assets/images/map-marker-selected.svg";
import Loader from 'components/Loader';
import Pagination from 'components/Pagination';
import { useSBSDispatch, useSBSState } from 'context/global';
import { useMarketplaceDispatch, useMarketplaceState } from 'context/marketplace';
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import moment from 'moment';
import { useEffect, useState } from 'react';
import { LayersControl, MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import MarkerClusterGroup from 'react-leaflet-cluster';
import { store } from "react-notifications-component";
import { Link, useHistory } from 'react-router-dom';
import { cleanEmpty } from 'services/general';
import { fetchHotels, fetchHotelsOffers, guestHotels, guestHotelsOffers, searchHotelFilters, searchOfferHotelFilters } from 'services/marketplace';
import Locale from "translations";
import HotelFilter from './HotelFilter';
import ListItemMap from './ListItemMap';



export default function HotelListMap() {
  const { locale, token_data } = useSBSState();
  const { marketPlace, messages } = Locale;
  const {  searchResults,hotelFilters } = useMarketplaceState();
  const dispatch = useMarketplaceDispatch();
  const [searchOrdering, setSearchOrdering] = useState({});
  const dispatchGlobal = useSBSDispatch();

  const search = window.location.search;
  const name = new URLSearchParams(search);

  // const [bounds, setBounds] = useState(null);
  const [allFilters, setAllFilters] = useState(false);
  const [priceRanges, setPriceRanges] = useState(null);
  const [price, setPrice] = useState({ min: priceRanges?.min, max: priceRanges?.max });
  const [selectedHotelIndex, setSelectedHotelIndex] = useState();
  const [Night, setnight] = useState(
    moment(hotelFilters?.date_to).diff(
      moment(hotelFilters?.date_from),
      "days"
    )
  );
  // search filter when item change in the filter list get the data from the backend
  const searchFilters = async (filters, page = 0) => {
    let data = {
      search_ordering: searchOrdering,
      ...filters,
      page: page,
      token: token_data?.token,
    };
    data = cleanEmpty(data);

    const response =
      token_data && token_data?.token != null
        ? await searchOfferHotelFilters(data)
        : await searchHotelFilters(data);

    dispatch({ type: "searchResults", payload: response?.data });
  };

  // go to pages paginations
  const goTo = async (e) => {
    let data = {
      search_ordering: searchOrdering,
      ...allFilters,
      page: e,
      token: token_data?.token,
    };
    data = cleanEmpty(data);

    const response =
      token_data && token_data?.token != null
        ? await searchOfferHotelFilters(data)
        : await searchHotelFilters(data);
    dispatch({ type: "searchResults", payload: response.data });
  };

  const history = useHistory();

  // get last result on page reload
  // TODO Refactor
  useEffect(() => {
    async function fetchData() {
      const hotelData = {
        ...hotelFilters,
        lang: locale,
      };

      if (searchResults === undefined) {
        
        const offer = localStorage.getItem("hotelOffer");
        let response;

        let x = {};
        name.forEach(function (value, key) {
          x = { ...x, [key]: value };
        });

        if (x.hasOwnProperty("token")) {
          response = x.hasOwnProperty("offer")
            ? await guestHotelsOffers(x)
            : await guestHotels(x);

          localStorage.setItem(
            "hotelFilters",
            JSON.stringify(response.data.token_data.search_payload)
          );
          dispatch({
            type: "searchBy",
            payload: response.data.token_data.search_payload,
          });

          localStorage.setItem("discount", response.data.token_data.discount);
          dispatchGlobal({
            type: "searchOffer",
            payload: response.data.token_data,
          });

          let search_payload = response.data.token_data.search_payload;

          setnight(
            moment(search_payload?.date_to).diff(
              moment(search_payload?.date_from),
              "days"
            )
          );
        } else {
          response =
             +offer
                ? await fetchHotelsOffers(hotelData)
                : await fetchHotels(hotelData)
        }

        if (response.data.data.length > 0) {
          dispatch({ type: "searchResults", payload: response.data });
          setPriceRanges({
            min: response.data.filter_data.price.min_price,
            max: response.data.filter_data.price.max_price,
          });
        } else {
          store.addNotification({
            title: "Error!",
            message: messages.noResults + " !",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 4000,
              onScreen: true,
              pauseOnHover: true,
            },
          });

          setTimeout(() => {
            history.push('/')
          }, 4000);
        }
      } else if (searchResults.length === 0);
    }
    fetchData();
  }, [searchResults]);



  useEffect(() => {
    const localPriceRanges = searchResults?.filter_data;
    if (localPriceRanges) {
      setPriceRanges({
        min: localPriceRanges?.price?.min_price,
        max: localPriceRanges?.price?.max_price,
      });
    }
  }, [searchResults?.filter_data]);

  useEffect(() => {
    if (allFilters) {
      setAllFilters({
        ...allFilters,
        search_filters: { ...allFilters?.search_filters, price: { min: price?.min, max: price.max } }
      });
      searchFilters({
        ...allFilters,
        search_filters: { ...allFilters?.search_filters, price: { min: price?.min, max: price.max } }
      });
    }
  }, [price]);
  // normal leaflet icon
  const mapMarkerIcon = new L.Icon({
    iconUrl: markerIcon,
    iconRetinaUrl: markerIcon,
    iconSize: [40, 64],
    shadowSize: [50, 64],
    iconAnchor: [22, 94],
    shadowAnchor: [4, 62],
    popupAnchor: [-3, -76],
  });
  // selected leaflet icon
  const mapMarkerIconSelected = new L.Icon({
    iconUrl: markerIconSelected,
    iconRetinaUrl: markerIconSelected,
    iconSize: [40, 64],
    shadowSize: [50, 64],
    iconAnchor: [22, 94],
    shadowAnchor: [4, 62],
    popupAnchor: [-3, -76],
  });
  // when hotel details popup open
  function onOpenPopUp(hotelIndex) {
    setSelectedHotelIndex(hotelIndex)
  }
  // when hotel popup close
  function onClosePopUp(e) {
    setSelectedHotelIndex(null);
  }
  let bounds = searchResults?.data?.map(hotel => {
    return [hotel?.latitude, hotel?.longitude]
  }) || [];

  return searchResults !== undefined ? (
    <div className="hotel-map">
      <div className="container">
        <div className="hotel-map-back-btn">
          <Link to="market-view">
            {locale === "en" ? <i className="fas fa-arrow-left mx-1"></i> : null}
            {marketPlace.backToHotels}
            {locale === "ar" ? <i className="fas fa-arrow-left mx-1"></i> : null}
          </Link>
        </div>
        <div className="tab-title py-3">
          <div className="row">
            {/* side fitler */}
            <div className="col-md-3 col-sm-12 hotel-map-filters">
              <HotelFilter
                searchFilters={searchFilters}
                setAllFilters={setAllFilters}
                maxPrice={priceRanges?.max}
                minPrice={priceRanges?.min}
                setPrice={setPrice}
                price={price}
              />
            </div>
            {/* hotels list */}
            <div className={`col-md-3 col-sm-12  hotel-map-list ${searchResults?.data?.length > 0 ? "col-md-3" : "col-md-9"}`}>
              {searchResults?.data?.length === 0 ?
                <p className="text-center mt-5">No Results</p>
                :
                searchResults.data.map((item, i) => {
                  return (
                    <ListItemMap
                      key={`hotel-${item?.hotel_id}`}
                      item={item}
                      searchBy="hotel"
                      Night={Night}
                    />
                  );
                })
              }
              <Pagination
                info={searchResults.meta}
                goTo={goTo}
                items={10}
                withDetailsText={false}
              />
            </div>

            {/* map */}
            <div className="col-md-6 col-sm-12">
              {bounds?.length > 0 ?
                <MapContainer bounds={bounds} key={bounds[0]}>
                  <LayersControl position="topright">
                    {/* <LayersControl.Overlay  name="google layer 2">

                <TileLayer
                  attribution='SBS'
                  url={'http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}'}
                />
                </LayersControl.Overlay> */}
                    <LayersControl.Overlay checked name="Google Layer">
                      <TileLayer
                        attribution='SBS'
                        url={'https://mt1.google.com/vt/lyrs=p&x={x}&y={y}&z={z}'}
                      />
                    </LayersControl.Overlay>
                    {/* <LayersControl.Overlay name="osm">
                    <TileLayer
                      attribution='SBS'
                      url={"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"}
                    />
                  </LayersControl.Overlay> */}
                  </LayersControl>
                  <MarkerClusterGroup chunkedLoading>
                    {searchResults?.data?.map((hotel, i) => {
                      return (
                        <Marker
                          key={i}
                          position={[hotel?.latitude, hotel?.longitude]}
                          icon={selectedHotelIndex === i ? mapMarkerIconSelected : mapMarkerIcon}
                          eventHandlers={{ popupclose: onClosePopUp, popupopen: () => onOpenPopUp(i) }}
                        >
                          <Popup>
                            <ListItemMap
                              key={`map-${hotel?.hotel_id}`}
                              item={hotel}
                              searchBy="hotel"
                              Night={Night}
                            />
                          </Popup>
                        </Marker>
                      )
                    })}
                  </MarkerClusterGroup>
                </MapContainer>
                :
                null
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  );
}

