import React from "react";
import Locale from "translations";
import carImage from "assets/images/bus.jpg";
import walletImage from "assets/images/newWallat.svg";
import useShowPermission from "hooks/useShowPermission";
import moment from "moment";

function TransferListItem({ onDetails, addToCart, transfer }) {
	const ManagetransferPermission = useShowPermission({
		permission: ["Manage-Marketplace-Transfer"],
	});
	const { marketPlace } = Locale;
	return (
		<div className="mt-3 bg-white border p-3 d-flex flex-wrap">
			<div className="w-100 d-flex flex-column flex-md-row">
				<div
					style={{ height: 150, width: 150, minHeight: 150 }}
					className="border"
				>
					<img
						alt="Transfer"
						className="img-fluid"
						src={transfer?.image ?? carImage}
						height="100%"
						onError={(e) => {
							e.target.onerror = null;
							e.target.crossOrigin = null;
						}}
					/>
				</div>
				<div className="ms-2 flex-grow-1">
					<span className="font-weight-bold text-dark-blue h5">
						{transfer?.type}{" "}
					</span>
					<span className="text-dark-blue">{transfer?.category} </span>
					<span className="h6 text-uppercase text-muted">
						{transfer?.vehicle}{" "}
					</span>
					<p>
						<i class="far fa-clock mx-1 text-pending" />
						Maximum Customer Waiting Time : {transfer?.customer_waiting_time}
					</p>
					<p>
						Maximum Supplier Waiting Time:{" "}
						{transfer?.domestic_supplier_waiting_time}(Domestic)
					</p>
					<p>
						Maximum Supplier Waiting Time:{" "}
						{transfer?.international_supplier_waiting_time} (International)
					</p>
					<p>
						{`${transfer.departure_trip_flight_direction} Flight Time ${moment(
							transfer.departure_trip_flight_time
						).format("YYYY-MM-DD hh:mm a")}`}
					</p>
					<p>
						{`${marketPlace.transferPickupTime}: `}
						{`${moment(transfer?.pickup_time).format("YYYY-MM-DD hh:mm a")}`}
					</p>

					{transfer?.return_time && (
						<>
							<hr className="my-1" />
							<p>
								{`${transfer.return_trip_flight_direction} Flight Time ${moment(
									transfer.return_trip_flight_time
								).format("YYYY-MM-DD hh:mm a")}`}
							</p>
							<p>
								{`${marketPlace.transferPickupTime}: `}
								{`${moment(transfer?.return_time).format(
									"YYYY-MM-DD hh:mm a"
								)}`}
							</p>
						</>
					)}
					<div className="d-flex w-100 justify-content-between align-items-end flex-md-row flex-column">
						<div>
							<u
								role="button"
								onClick={onDetails}
								className="font-weight-bold text-primary"
							>
								<p>{marketPlace.messages.viewMoreDetails}</p>
							</u>
						</div>
						{/* <ShowForPermission permission={["Manage-Marketplace-Transfer"]}> */}
						<button
							onClick={addToCart}
							className="border p-2 d-flex position-relative transfer-wallet"
							disabled={!ManagetransferPermission}
						>
							<img alt="wallet" src={walletImage} />
							<div className="mx-2">
								<p className="text-pending">{marketPlace.Price}</p>
								<h5 className="terms-color font-weight-bold">
									{transfer?.price} {transfer?.currency}
								</h5>
							</div>
						</button>
						{/* </ShowForPermission> */}
					</div>
				</div>
			</div>
		</div>
	);
}

export default TransferListItem;
