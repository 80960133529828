import React from 'react'
import clearIcon from "assets/images/wallet/clearIcon.svg";
import correctIcon from "assets/images/wallet/correctIcon.svg";
import Locale from 'translations';
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import DateRangeField from 'components/Form/DateRangeField/DateRangeField';
import DatePickerField from 'components/Form/DatePickerField/DatePickerField';

function Filterrequestes({ filters, setFilters, getRequestsList }) {
	const { payment, inventory } = Locale;
	const allstatus = [
		{ id: 0, name: `${payment.messages.Pending}`, title: "pending" },
		{ id: 1, name: `${inventory.messages.confirmed}`, title: "paid" },
		{ id: 2, name: `${inventory.messages.rejected}`, title: "rejected" },
		{ id: 3, name: `${inventory.messages.accepted}`, title: "approved" },
		{ id: 4, name: `${inventory.messages.expired}`, title: "expired" },
	];


	const altripstype = [
		{ id: 1, name: "hajj", value: "hajj" },
		{ id: 2, name: "umrah", value: "umrah" },
		{ id: 3, name: "umrah plus", value: "umrah plus" },
		{ id: 4, name: "tourism", value: "tourism" },
		{ id: 5, name: "other", value: "other" }
	];

	function resetFilters() {
		setFilters({
			trip_name: "",
			trip_type: "",
			departure_date: "",
			status: "",
		});
		getRequestsList({
			trip_name: "",
			trip_type: "",
			departure_date: "",
			status: "",
		});
	}

	return (
		<>
			<div className="filter-update bg-filter-gray border">
				<div className="product-buttons col-md-12">
					<div className="main-filter-payment d-flex justify-contain-between">
						<div className="main-label controls-field col-6 col-md-2">
							<TextField
								label={payment.messages.search}
								type="text"
								placeholder={payment.messages.search}
								value={filters?.trip_name}
								onChange={(e) =>
									setFilters({
										...filters,
										trip_name: e.target.value,
									})
								}
							/>
						</div>
						<div className="main-label mx-md-2 col-6 col-md-2 controls-field">
							<SelectField
								label={inventory.messages.tripType}
								placeholder={inventory.messages.tripType}
								options={altripstype}
								value={filters?.trip_type?.label}
								onChange={(e) =>
									setFilters({
										...filters,
										trip_type: e,
									})
								}
							/>
						</div>

						<div className="main-label mx-md-2 col-6 col-md-2 controls-field">
							<DatePickerField
								label={"Date"}
								date={filters?.departure_date}
								onDateChange={(date) => {
									setFilters({
										...filters,
										departure_date: date,
									})
								}}
								isOutsideRange={(day) => {
									return false;
								}}
							/>
						</div>
						<div className="main-label mx-md-2 col-6 col-md-2 controls-field">
							<SelectField
								label={inventory.messages.reservationStatus}
								placeholder={inventory.messages.reservationStatus}
								options={allstatus}
								value={filters?.status?.label}
								onChange={(e) =>
									setFilters({
										...filters,
										status: e,
									})
								}
							/>
						</div>
						<div className="filter-btn col-12 col-md-3 d-flex align-items-center justify-content-start">
							<div className="apply pointer">
								<button className="btn btn-light px-3"
									onClick={() => { getRequestsList(filters)}}
								>
									<img src={correctIcon} alt=" " /> {payment.messages.apply}
								</button>
							</div>
							<div className="clear text-secondary pointer">
								<button className="btn btn-light px-3 mx-2" onClick={()=> {
									resetFilters();
								}}>
									<img src={clearIcon} alt=" " /> {payment.messages.clear}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Filterrequestes