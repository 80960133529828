import clearIcon from "assets/images/wallet/clearIcon.svg";
import correctIcon from "assets/images/wallet/correctIcon.svg";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import { useSBSState } from "context/global";
import Locale from "../../translations";

export default function Filter({ filter, setFilter, search, groupStatus }) {
	// ----------------------------------------------------------------------------------------------------
	// Component States / Variables / Constants
	// ----------------------------------------------------------------------------------------------------
	const { visa } = Locale;
	const { locale } = useSBSState();
	// const status = [
	// 	{ id: 0, name: `${visa.active}`, title: `Active` },
	// 	{ id: 1, name: `${visa.inactive}`, title: `Inactive` },
	// ];
	const status =
		groupStatus?.length > 0 &&
		groupStatus?.map((e) => ({
			id: e?.group_status_id,
			name: e[locale],
		}));


		// Reset Filter
	const resetFilter = () => {
		setFilter({
			search: "",
			status: "",
			page: 0,
			size: 10,
			clear: !filter.clear,
		});
	};

	// Main Component Body UI
	return (
		<div className="filter-update bg-filter ">
			<div className="main-filter d-flex align-items-center">
				<div className="row align-items-center w-100 no-gutter m-0 controls-field ">
					<div className="col-md-3">
						<div className="main-label">
							<TextField
								hasLabel={true}
								type="text"
								label={visa.search}
								placeholder={visa.search}
								value={filter.search}
								onChange={(e) =>
									setFilter({ ...filter, search: e.target.value })
								}
							/>
							{/* <i className="fas fa-search fa-fw"></i> */}
						</div>
					</div>

					<div className="col-md-2">
						<SelectField
							//hasLabel={false}
							label={visa.status}
							placeholder={visa.status}
							options={status}
							value={filter.status?.name}
							onChange={(e) => {
								setFilter({
									...filter,
									status: { id: e.value, name: e.label },
								});
							}}
						/>
					</div>

					{/* Reset Filter */}
					<div className="filter-btn col-md-2 d-flex align-items-center justify-content-around">
						<div className="apply pointer">
							<button className="btn btn-light" onClick={search}>
								<img src={correctIcon} alt=" " />
								{visa.apply}
							</button>
						</div>
						<div className="clear text-secondary pointer" onClick={resetFilter}>
							<button className="btn btn-light ">
								<img src={clearIcon} alt=" " />
								{visa.clear}
							</button>
						</div>
					</div>
				</div>

				{/* <div className="main-filter col-md-2  	">
					<div className=" w-100 no-gutter text-right ">
						<Link
							to="/visa/manage-contract"
							className="btn btn-primary px-5 py-2 "
						>
							{visa.ManageContract}
						</Link>
					</div>
				</div> */}
			</div>
		</div>
	);
}
