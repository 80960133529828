export const VoucherPrinte = `
<style>
* {
  font-family: "Cairo", sans-serif;
  box-sizing: border-box;
}

html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
	margin: 0;
	padding: 0;
}

p {
  margin: 0;
}




.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
margin-bottom: 2px;
}




.voucher-header .header-img {
border: 1px solid #ebebeb;
width: 210px;
display: flex;
align-items: center;
justify-content: center;
}
.header-img img {
	width:210px;
} 

.voucher-header .header-name h4 {
color: #0c3b5c;
font-size: 18px;
font-weight: 700;
margin: 7px 0;
}
.voucher-header .header-data {
width: 60%;
}

.voucher-header .header-data div {
margin: 3px 0;
}
.voucher-header .header-data h5 {
color: #2d2d2d;
font-size: 18px;
}
.voucher-header .header-data p {
color: #2d2d2d;
font-weight: 800;
font-size: 14px;
}

.voucher-header {
gap: 85px;
}

hr {
border-top: 1px solid #0c3b5c !important;
}

.services h3 {
color: #0c3b5c;
font-size: 18px;
font-weight: 700;
}

.services .service-data div {
margin: 3px 0;
}
.services .service-data h5 {
color: #2d2d2d;
font-size: 20px;
}
.services .service-data p {
color: #2d2d2d;
font-weight: 800;
font-size: 17px;
}

.passenger h3 {
color: #0c3b5c;
font-size: 18px;
font-weight: 700;
}
.passenger .passenger-data div {
margin: 3px 0;
}
.passenger .passenger-data h5 {
color: #2d2d2d;
font-size: 20px;
}
.passenger .passenger-data p {
color: #2d2d2d;
font-weight: 800;
font-size: 18px;
}
.passenger .passenger-data .passenger-info h6 {
color: #2d2d2d;
}
.passenger .passenger-data .passenger-info p {
color: #0c3b5c;
}
.passenger .passenger-data .passenger-info {
background: #f7f7f7;
border: 4px solid #f4f4f4;
padding: 25px;
}

html,
.bg-gray-100 {
background-color: #fff !important;
}

.voucher-header .header-img {
border: 1px solid #ebebeb;
width: 210px !important;
display: flex;
align-items: center;
justify-content: center;
}

.voucher-header .header-name h4 {
color: #0c3b5c;
font-size: 16px !important;
font-weight: 700;
margin: 7px 0;
}

.voucher-header .header-data div {
margin: 3px 0;
}
.voucher-header .header-data h5 {
color: #2d2d2d;
font-size: 16px !important;
}
.voucher-header .header-data p {
color: #2d2d2d;
font-weight: 800;
font-size: 14px !important;
}
.voucher-header .header-data {
width: 60%;
}

.voucher-header {
gap: 20px !important;
}

.services h3 {
color: #0c3b5c;
font-size: 14px !important;
font-weight: 500 !important;
}
.services .service-data div {
margin: 3px 0;
}
.services .service-data h5 {
color: #2d2d2d;
font-size: 15px !important;
}
.services .service-data p {
color: #2d2d2d;
font-weight: 500 !important;
font-size: 15px !important;
}

.passenger h3 {
color: #0c3b5c;
font-size: 14px !important;
font-weight: 500 !important;
}
.passenger .passenger-data div {
margin: 3px 0;
}
.passenger .passenger-data h5 {
color: #2d2d2d;
font-size: 16px !important;
}
.passenger .passenger-data p {
color: #2d2d2d;
font-weight: 500 !important;
font-size: 15px !important;
}
.passenger .passenger-data .passenger-info h6 {
color: #2d2d2d;
}
.passenger .passenger-data .passenger-info p {
color: #0c3b5c;
}
.passenger .passenger-data .passenger-info {
background: #f7f7f7;
border: 4px solid #f4f4f4;
padding: 10px !important;
}

.voucher h3 {
background-color: #fff;
}
.sidebar.office-navbar {
display: none !important;
}

.bg-white {
background-color: #fff !important;
}
.p-4 {
padding: 1.5rem !important;
}
.d-flex {
display: flex !important;
}

.flex-column {
flex-direction: column;
}
.text-center {
text-align: center !important;
}
.text-success {
color: #00b545 !important;
}
.font-weight-bold {
font-weight: 700 !important;
}

.py-2 {
padding-top: 0.5rem !important;
padding-bottom: 0.5rem !important;
}

.py-1 {
padding-top: 0.25rem !important;
padding-bottom: 0.25rem !important;
}
.px-4 {
padding-right: 1.5rem !important;
padding-left: 1.5rem !important;
}
.mb-5 {
margin-bottom: 3rem !important;
}
.mb-3 {
margin-bottom: 1rem !important;
}
.mb-2 {
margin-bottom: 0.5rem !important;
}
.my-2 {
margin-top: 0.5rem !important;
margin-bottom: 0.5rem !important;
}
.my-1 {
margin-top: 0.25rem !important;
margin-bottom: 0.25rem !important;
}

@media (min-width: 576px) {
.col-sm-8,
.col-sm-9 {
flex: 0 0 auto;
}
.col-sm-8 {
width: 66.66666667%;
}
.col-sm-4,
.col-sm-5 {
flex: 0 0 auto;
}

.col-sm-4 {
width: 33.33333333%;
}
}
.col-9 {
width: 75%;
}
.col-8,
.col-9 {
flex: 0 0 auto;
}
.col-8 {
width: 66.66666667%;
}
.col-7 {
width: 58.33333333%;
}
.col-6,
.col-7 {
flex: 0 0 auto;
}
.col-6 {
width: 50%;
}
.col-5 {
width: 41.66666667%;
}
.col-4,
.col-5 {
flex: 0 0 auto;
}
.col-4 {
width: 33.33333333%;
}
.col-3 {
width: 25%;
}
.col-2,
.col-3 {
flex: 0 0 auto;
}
.col-2 {
width: 16.66666667%;
}
.row {
--bs-gutter-x: 20px;
--bs-gutter-y: 0;
display: flex;
flex-wrap: wrap;
margin-right: -0.5;
margin-left: -0.5;
}
.row {
display: flex;
flex-wrap: wrap;
margin-right: -15px;
margin-left: -15px;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
padding-right: 5px;
padding-left: 5px;
}
.col-4 {
flex: 0 0 33.3333333333%;
max-width: 33.3333333333%;
}
.col-6 {
flex: 0 0 50%;
max-width: 50%;
}
.col-3 {
flex: 0 0 25%;
max-width: 25%;
}
.col-8 {
flex: 0 0 66.6666666667%;
max-width: 66.6666666667%;
}
.col-2 {
flex: 0 0 16.6666666667%;
max-width: 16.6666666667%;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
padding-right: 5px;
padding-left: 5px;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
padding-right: 5px;
padding-left: 5px;
}
.mb-1,
.my-1 {
margin-bottom: 0.25rem !important;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
padding-right: 5px;
padding-left: 5px;
}

table {
  border: 4px solid black;
  border-collapse: collapse;
	width:90%;
	margin-inline: auto;
  margin-block: 15px !important;

}

table tr{
	border-bottom: 4px solid black;
	height:96vh;
	vertical-align: baseline;
	}
	tr::before, tr::after
	{
		content:"";
		height:100px;
		display:block;
		background:red 
	}

</style>

<table style="">
  
  <tr>
    <td>
<div class="voucher container bg-white p-4">
			<div class="container-wrapper p-4">
				<div class="voucher-header d-flex  my-5">
					<div class="d-flex flex-column header-name">
						<div class="header-img  p-4 text-center">
							[avatar]
						</div>
						<h4>[companyName]</h4>
					</div>

					<div class="header-data">
						<div class="row">
							<div class="col-7">
								<h5>[messages_bookingReference]: </h5>
							</div>
							<div class="col-5">
								<p>[bookingCode]</p>
							</div>
						</div>

	

						<div class="row">
							<div class="col-7">
								<h5>[messages_confirmationNO] :</h5>
							</div>
							<div class="col-5">
								<p>[confirmationNumber]</p>
							</div>
						</div>

						<div class="row">
							<div class="col-7">
								<h5>[messages_bookingStatus] :</h5>
							</div>
							<div class="col-5">
								<p class="text-success">[bookingStatus]</p>
							</div>
						</div>

						<div class="row">
							<div class="col-7">
								<h5>[messages_bookedBy] :</h5>
							</div>
							<div class="col-5">
								<p>[bookedBy]</p>
							</div>
						</div>
					</div>
				</div>
				<hr />

				<div class="services my-3">
					<h3>[messages_serviceProviderDetails]</h3>
					<div class="service-data px-4 py-2">
						<div class="row">
							<div class="col-5">
								<h5>[messages_hotelName] :</h5>
							</div>
							<div class="col-7">
								<p>[hotel_name]</p>
							</div>
						</div>
						<div class="row">
						<div class="col-5">
							<h5>[messages_rate] :</h5>
						</div>
						<div class="col-7">
							<p>[rate_value]</p>
						</div>
					</div>

					<div class="row">
					<div class="col-5">
						<h5>[messages_city] :</h5>
					</div>
					<div class="col-7">
						<p>[passengercity]</p>
					</div>
				</div>

				


						<div class="row">
							<div class="col-5">
								<h5>[messages_address] :</h5>
							</div>
							<div class="col-7">
								<p>[hotel_address]</p>
							</div>
						</div>
            [hotel_phone]

					

					



					</div>
				</div>
				<hr />

				<div class="passenger my-3">
					<h3>[messages_passengerDetails]</h3>
					<div class="passenger-data px-4 py-2">
						<div class="row">
							<div class="col-4">
								<h5>[messages_passengerName] :</h5>
							</div>
							<div class="col-8">
								<p>[passengerName]</p>
							</div>
						</div>
						<div class="row">
							<div class="col-4">
								<h5>[messages_passengerNationality] :</h5>
							</div>
							<div class="col-8">
								<p>[passengerNationality]</p>
							</div>
						</div>
						<div class="row">
							<div class="col-4">
								<h5>[messages_CountryResidence] :</h5>
							</div>
							<div class="col-8">
								<p>[passengerCountry]</p>
							</div>
						</div>
						<div class="row">
							<div class="col-4">
								<h5>[messages_serviceType] :</h5>
							</div>
							<div class="col-8">
								<p>[passengerservice_type]</p>
							</div>
						</div>
						
						<div class="passenger-info my-2">
							<div class="row">
								<div class="mb-3 col-sm-4">
									<h6 class="">[messages_checkin]</h6>
									<p class="font-weight-bold">[checkin]</p>
								</div>
								<div class="mb-3 col-sm-4">
									<h6>[messages_checkOut]</h6>
									<p class="font-weight-bold">[checkout]</p>
								</div>
								<div class="mb-3 col-sm-4">
									<h6>[messages_roomType]</h6>
									<p class="font-weight-bold">[roomType]</p>
								</div>

								<div class="mb-2 col-sm-8">
									<h6>[messages_roomCategory]</h6>
									<p class="font-weight-bold">[roomCategory]</p>
								</div>
								<div class="mb-2 col-sm-4">
									<h6>[messages_MealPlan]</h6>
									<p class="font-weight-bold">[rateBasis]</p>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-4">
								<h5>[messages_additionalRequests] :</h5>
							</div>
							<div class="col-8">
								<p>[additionalRequests]</p>
							</div>
						</div>

						<div class="row ">
						<div class="col-4">
							<h5>[messages_accommodation] :</h5>
						</div>
						<div class="col-8">
							<p>[accommodation_value]</p>
						</div>

						<div class="col-12">
						<p>[specific_text]</p>
					</div>
					</td>
 
					</tr>
					
					[rate_comments_msg_value]
					
</table>
		`
