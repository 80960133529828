import walletImg from "assets/images/inventory/walletSm.svg";
import { Link, useHistory } from "react-router-dom";
import Locale from "translations";

export default function TableHotelGroupContent({ index, groupItem }) {
	const { inventory } = Locale;
	let history = useHistory();
	/************************** */
	const statusLocale = (status) => {
		switch (status) {
			case "pending":
				return inventory.messages.pending;
			case "waiting":
				return inventory.messages.waiting;
			case "cancelled":
				return inventory.messages.cancelled;
			case "issued":
				return inventory.messages.issued;
			case "in progress":
				return inventory.messages.inProgress;
			case "expired":
				return inventory.messages.expired;
			case "incomplete":
				return inventory.messages.incomplete;
			case "New_request":
				return inventory.messages.NewRequest;
			case "tentative":
				return inventory.messages.tentative;
			case "definite":
				return inventory.messages.definite;			
			default:
				return status;
		}
	};
	return (
		<>
			<tr>
				<td>{groupItem.id}</td>
				<td>{groupItem.reference_number}</td>
				<td>{groupItem.hotel_name}</td>
				<td>{groupItem.rooms_count}</td>
				<td>{groupItem.created_at}</td>
				<td>{groupItem.date_from}</td>
				<td>{groupItem.nights}</td>
				<td>{groupItem.total_price}</td>
				<td>{groupItem.pax}</td>
				<td>
					<p
						className={`text-white text-capitalize py-1 rounded text-center ${
							groupItem.status === "definite"
								? "bg-success"
								: groupItem.status === "canceled"
								? "bg-danger"
								: "bg-dark-yellow"
						}`}
					>
						{statusLocale(groupItem.status)}
					</p>
				</td>
				<td>
					<Link
						className="text-yellow"
						to={`/Hotels/inventory-group/${groupItem?.id}`}
					>
						<i className="fas fa-eye "></i>{inventory.messages.view}
					</Link>
				</td>
				<td>
					{groupItem.should_pay && groupItem.status !== "canceled" ? (
						<button
							className="btn btn-link text-danger font-weight-bolder text-underline"
							onClick={() => {
								history.push(
									`/Hotels/inventory-group/${groupItem?.id}?scroll=true`
								);
							}}
						>
							<img src={walletImg} alt="" width={20} height={20} />
							<span className="mt-3">{inventory.messages.paynow}</span>
						</button>
					) : null}
					</td>
					
			</tr>
		</>
	);
}
