import axios from "axios";
const profileURL = process.env.REACT_APP_API_URL + "/v1/company-management";

export const fetchUserProfile = async () => {
	return await axios
		.get(`${profileURL}/view_user_profile`)
		.then((res) => res.data.data);
};

export const updateUserProfile = async (data) => {
	return await axios
		.post(`${profileURL}/edit_user_profile`, data)
		.then((res) => res);
};

export const fetchCompanyProfile = async () => {
	return await axios
		.get(`${profileURL}/company_info`)
		.then((res) => res.data.data);
};

export const updateCompanyProfile = async (data) => {
	return await axios
		.post(`${profileURL}/edit_company_profile`, data)
		.then((res) => res);
};
export const deleteCompanyAccount = async () => {
	return await axios
		.post(`${profileURL}/delete-my-account`)
		.then((res) => res);
};
