export function InboundIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="11.971"
			height="11.971"
			viewBox="0 0 11.971 11.971"
		>
			<path
				id="Path_11730"
				data-name="Path 11730"
				d="M11.139,7.368,9.447,7.351a.344.344,0,0,1-.239-.591L11.764,4.2a.7.7,0,1,0-1-1L8.21,5.764A.36.36,0,0,1,7.6,5.506l0-1.68a.729.729,0,0,0-.72-.718.7.7,0,0,0-.7.709l0,3.525a1.411,1.411,0,0,0,1.41,1.409c.549,0-.035,0,3.528.007a.7.7,0,0,0,.711-.7.683.683,0,0,0-.692-.691m-1.2,4.489a16.076,16.076,0,0,0-1.5-.772c-.989-.4-.922.789-1.629,1.176-1,.549-4.266-2.631-4.118-3.617.114-.763,1.017-.8.759-1.735-.138-.5-.385-.992-.57-1.474-.249-.646-.35-1.06-1.074-1.024a1.49,1.49,0,0,0-1.19.667,3.761,3.761,0,0,0-.154,3.9c1.639,3.485,4.984,5.768,7.389,5.985a2.493,2.493,0,0,0,2.516-1.4l-.056.168.056-.168.056-.172-.056.17c.287-.873.322-1.293-.433-1.7m.377,1.87h0m.113-.34h0"
				transform="translate(0 -3)"
				fill="#709a47"
				fill-rule="evenodd"
			/>
		</svg>
	);
}

export function OutboundIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="11.971"
			height="11.971"
			viewBox="0 0 11.971 11.971"
		>
			<path
				id="Path_11732"
				data-name="Path 11732"
				d="M11.967,4.415a1.411,1.411,0,0,0-1.41-1.41C10.009,3,10.593,3,7.03,3a.7.7,0,0,0-.711.7.683.683,0,0,0,.691.692L8.7,4.406A.343.343,0,0,1,8.942,5L6.386,7.555a.7.7,0,1,0,1,1L9.939,5.993a.361.361,0,0,1,.61.258l0,1.68a.731.731,0,0,0,.72.719.7.7,0,0,0,.7-.71ZM9.94,11.857a16.165,16.165,0,0,0-1.5-.772c-.989-.4-.922.789-1.629,1.176-.459.251-1.106-.2-1.507-.456a7.128,7.128,0,0,1-1.836-1.692c-.263-.343-.847-.988-.775-1.469.113-.763,1.017-.8.758-1.735-.138-.5-.385-.992-.57-1.474-.249-.646-.35-1.059-1.074-1.024a1.489,1.489,0,0,0-1.19.668,3.76,3.76,0,0,0-.154,3.9,11.959,11.959,0,0,0,3.757,4.483,8.016,8.016,0,0,0,3.632,1.5,2.49,2.49,0,0,0,2.516-1.4l-.056.168.056-.168c.032-.1.049-.15.056-.173l-.056.17c.287-.874.322-1.294-.433-1.7Zm.377,1.87Zm.113-.34Z"
				transform="translate(0 -3)"
				fill="#ee2548"
				fill-rule="evenodd"
			/>
		</svg>
	);
}
