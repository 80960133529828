import walletImg from "assets/images/bankDetails.svg";
//import ImagePlaceholder from "assets/images/image_placeholder.png";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Locale from "translations";

export default function BankModal({ modalBank, toggleModalBank, bankDetails }) {
	const { payment } = Locale;
	const onlineVisaURL = process.env.REACT_APP_API_URL + "/v1/files/fetch/";

	return (
		<div>
			<Modal
				size="lg"
				isOpen={modalBank}
				toggle={toggleModalBank}
				className="recharge-Modal"
			>
				<ModalHeader
					className="align-items-center py-1  border-none"
					toggle={toggleModalBank}
				>
					{payment.messages.SafaBankDetails}
				</ModalHeader>
				<ModalBody>
					<div className="container-custom-lg-modal bank-modal">
						<div className="modal-layer d-flex">
							<div className="w-40 wallet-image">
								<img src={walletImg} alt="" />
							</div>
							<div className="w-70 bank-modal-content mx-2 ">
								{bankDetails?.length > 0 ? (
									bankDetails.map((bankDetails, index) => {
										return (
											<>
												<img
													src={onlineVisaURL + bankDetails.logo}
													width={200}
													height={60}
													alt=""
												/>

												{/* {bankDetails[0]?.logo != null ? (
									<img
										src={onlineVisaURL + bankDetails[0]?.logo}
										width={200}
										height={40}
										alt=""
									/>
								) : (
									""
								)} */}

												<div>
													<span>{payment.messages.beneficiary}: </span>
													<p className="mx-1">
														{bankDetails?.beneficiary
															? bankDetails?.beneficiary
															: "-"}
													</p>
												</div>
												<div>
													<span>{payment.messages.bank}: </span>
													<p className="mx-1">
														{bankDetails?.bank_name
															? bankDetails?.bank_name
															: "-"}{" "}
													</p>
												</div>
												<div>
													<span>{payment.messages.branch}: </span>
													<p className="mx-1">
														{" "}
														{bankDetails?.branch ? bankDetails?.branch : "-"}
													</p>
												</div>
												<div>
													<span style={{whiteSpace: "nowrap"}}>{payment.messages.accountNo}: </span>
													<p className="mx-1">
														{bankDetails?.account_no
															? bankDetails?.account_no
															: "-"}
													</p>
												</div>
												<div>
													<span> {payment.messages.swift}: </span>
													<p className="mx-1">
														{bankDetails?.swift ? bankDetails?.swift : "-"}
													</p>
												</div>
												<div>
													<span> {payment.messages.IBAN}: </span>
													<p className="mx-1">
														{bankDetails?.iban ? bankDetails?.iban : "-"}
													</p>
												</div>
											</>
										);
									})
								) : (
									<div className="d-flex justify-content-center align-items-center">
										<h3 style={{ color: "#a2a2a2" }}>
											{" "}
											<i class="fas fa-exclamation"></i> No Data
										</h3>
									</div>
								)}
							</div>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
}
